import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import copy from "copy-to-clipboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import Frame from "../../Images/Frame.png";
import Modal from "@mui/material/Modal";
import LinkImg from "../../Images/link.png";
import CloseIcon from "../../Images/close.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import "./PaymentModal.css";
import {
  Alert,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import JtColor from "../../Images/jt-logo-color.png";
import "@fontsource/sora";
import axios from "axios";
import { InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";



const style = {};

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CalendarMonthIcon />
          </InputAdornment>
        ),
      }}
      ref={ref}
    />
  );
});

export default function PaymentModal({
  handleSecondOpen,
  handleSecondClose,
  secondOpen,
  handleOpen,
}) {
  const [error, setError] = useState(false);
  const [widthTotal, setWidth] = useState(window.innerWidth);
  let [copied, setCopied] = useState(false);
  let [loading, setLoading] = useState(false);
  let [form, setForm] = useState({
    name: "",
    course: "GMC",
    contact: "",

    email: "",
    amount: "",
    batch_date: new Date(),
  });
  let [link, setLink] = useState("");
  console.log(form, " checking form");

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleNewClick = () => {
    setForm({
      name: "",
      course: "GMC",
      contact: "",

      email: "",
      amount: "",
      batch_date: new Date(),
    });
    setLink("");
    setCopied(false);
    setLoading(false)
  };
  const handleSubmit = () => {
    setLoading(true);
    console.log("Line 72: ", form);

    const formattedDate = format(new Date(form.batch_date), "dd-MM-yyyy");

    // Create a new object with all the original form data, but replace batch_date with the formatted date
    const formDataWithFormattedDate = {
      ...form,
      batch_date: formattedDate,
    };

    fetch("https://app.joshtalks.org/api/skill/v1/payment/gmc_payment_link/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataWithFormattedDate),
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (!data.success || data.success !== true) {
          setLoading(false);
          setError(true);
          setForm({
            name: "",
            course: "GMC",
            contact: "",

            email: "",
            amount: "",
           batch_date:new Date()
          });
          setLink("");
          setCopied(false);
          await new Promise((resolve) =>
            setTimeout(() => {
              setError(false);
              resolve();
            }, 3000)
          );
          setLoading(false);

          return;
        }
        console.log(data, " line 110");
        setLink(data.razorpay_link);
        setLoading(false);
      });
  };
  console.log(link, " link checking");
  const handleChange = ({ name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const copyLink = () => {
    copy(link);
    setCopied(true);
  };
  console.log(form);
  return (
    <div>
      <Modal
        open={secondOpen}
        onClose={handleSecondClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="BoxStyle">
          <div
            style={{
              width: "100%",

              height: "100%",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",

              position: "relative",
            }}
          >
            <div id="navbarr">
              <img id="navimg" src={JtColor} alt="." />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "center",
                }}
              >
                <p className="navp">Generate New Payment Link</p>
                <div
                  style={{
                    marginTop: "2%",
                    paddingTop: "0px",
                    borderTop: "2px solid black",
                    width: "130%",
                  }}
                ></div>
              </div>
              <div
                style={{
                  marginRight: "5%",
                  width: "50px",
                }}
              >
                {/* <CloseIcon
                  onClick={handleSecondClose}
                  style={{
                    color: "blue",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                /> */}
                <img
                  id="cross"
                  onClick={handleSecondClose}
                  src={CloseIcon}
                  alt=""
                />
              </div>
            </div>
            <div id="secondmainDiv">
              <div
                style={{
                  width: widthTotal > 600 ? "50%" : "90%",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: widthTotal < 600 ? "100%" : "",
                  }}
                >
                  <InputLabel
                    style={{
                      fontFamily: "Sora",
                      fontStyle: "normal",
                      fontWeight: 600,
                      marginBottom: "5%",
                      fontSize: "16px",
                      lineHeight: "16px",
                    }}
                  >
                    Name
                  </InputLabel>
                  <OutlinedInput
                    placeholder="Enter The Name"
                    style={{
                      background: "#F4F4F4",

                      width: widthTotal > 600 ? "320px" : "100%",
                      color: "#2D3648",
                      fontFamily: "Inter",
                      fontStyle: "normal",

                      fontSize: "16px",
                    }}
                    onChange={(e) => {
                      handleChange(e.target);
                    }}
                    type="text"
                    name="name"
                    value={form.name}
                  />
                </div>
                <div
                  style={{
                    paddingTop: "20px",
                    width: widthTotal < 600 ? "100%" : "",
                  }}
                >
                  <InputLabel
                    style={{
                      fontFamily: "Sora",
                      fontStyle: "normal",
                      fontWeight: 600,
                      marginBottom: "5%",
                      fontSize: "16px",
                      lineHeight: "16px",
                    }}
                  >
                    Contact
                  </InputLabel>
                  <OutlinedInput
                    placeholder="Enter Contact Number"
                    style={{
                      background: "#F4F4F4",

                      width: widthTotal > 600 ? "320px" : "100%",
                      color: "black",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    onChange={(e) => {
                      handleChange(e.target);
                    }}
                    type="number"
                    name="contact"
                    value={form.contact}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <CallOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div
                  style={{
                    paddingTop: "20px",
                    width: widthTotal < 600 ? "100%" : "",
                  }}
                >
                  <InputLabel
                    style={{
                      fontFamily: "Sora",
                      fontStyle: "normal",
                      fontWeight: 600,
                      marginBottom: "5%",
                      fontSize: "16px",
                      lineHeight: "16px",
                    }}
                  >
                    Email
                  </InputLabel>
                  <OutlinedInput
                    placeholder="Enter your email address"
                    style={{
                      background: "#F4F4F4",

                      width: widthTotal > 600 ? "320px" : "100%",
                      color: "black",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    onChange={(e) => {
                      handleChange(e.target);
                    }}
                    type="text"
                    name="email"
                    value={form.email}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <EmailOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                {widthTotal > 600 ? (
                  <div
                    style={{
                      paddingTop: "20px",
                      flexDirection: "column",
                      display: "flex",
                      float: "right",

                      justifyContent: "right",
                    }}
                  >
                    <div id="createLink">
                      <Button
                        variant="contained"
                        style={{
                          whiteSpace: "nowrap"
                        }}
                        onClick={handleSubmit}
                        id="linkbtn"
                      >
                        Generate Link
                      </Button>

                      <input
                        type="text"
                        style={{
                          color: loading ? "green" : "",
                        }}
                        value={
                          link
                            ? link
                            : loading
                            ? "Generating... Please Wait"
                            : "Generate Link"
                        }
                        id="linkInput"
                      />

                      <button id="linkCopy" onClick={copyLink}>
                        {!copied ? (
                          <ContentCopyIcon style={{ color: "white" }} />
                        ) : (
                          <CheckIcon style={{ color: "white" }} />
                        )}
                      </button>
                    </div>
                    {link ? (
                      <img
                        src={Frame}
                        style={{
                          height: "13px",
                          width: "43px",
                          marginTop: "1%",
                          marginLeft: "20px",
                        }}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  width: widthTotal > 600 ? "50%" : "90%",
                  paddingTop: widthTotal < 600 ? "20px" : "",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: widthTotal < 600 ? "100%" : "",
                  }}
                >
                  <InputLabel
                    style={{
                      fontFamily: "Sora",
                      fontStyle: "normal",
                      fontWeight: 600,
                      marginBottom: "5%",
                      fontSize: "16px",
                      lineHeight: "16px",
                    }}
                  >
                    Course
                  </InputLabel>
                  <OutlinedInput
                    style={{
                      background: "#F4F4F4",

                      width: widthTotal > 600 ? "320px" : "100%",
                      color: "black",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    onChange={(e) => {
                      handleChange(e.target);
                    }}
                    type="text"
                    name="course"
                    defaultValue={"GMC"}
                    disabled={true}
                  />
                </div>

                <div
                  style={{
                    paddingTop: "25px",
                    width: widthTotal < 600 ? "100%" : "",
                  }}
                >
                  <InputLabel
                    style={{
                      fontFamily: "Sora",
                      fontStyle: "normal",
                      fontWeight: 600,
                      marginBottom: "5%",
                      fontSize: "16px",
                      lineHeight: "16px",
                    }}
                  >
                    Amount
                  </InputLabel>
                  <OutlinedInput
                    placeholder="Enter Amount"
                    style={{
                      background: "#F4F4F4",
                      border: "1px solid #73A0EC",
                      width: widthTotal > 600 ? "320px" : "100%",
                      color: "black",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      paddingLeft: "0px",
                      marginLeft: "0px",
                    }}
                    onChange={(e) => {
                      handleChange(e.target);
                    }}
                    type="text"
                    name="amount"
                    value={form.amount}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{
                          paddingLeft: "0px",
                          marginLeft: "0px",
                        }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          style={{
                            paddingLeft: "0px",
                            marginLeft: "0px",
                          }}
                        >
                          <CurrencyRupeeIcon
                            style={{
                              width: "50px",
                              height: "55px",
                              backgroundColor: "#73A0EC",
                              color: "white",
                              paddingLeft: "0px",
                              marginLeft: "0px",
                              border: "1px solid #73A0EC",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>

                <div
                  style={{
                    paddingTop: "25px",

                    width: widthTotal < 600 ? "100%" : "",
                  }}
                >
                  <InputLabel
                    style={{
                      fontFamily: "Sora",
                      fontStyle: "normal",
                      fontWeight: 600,
                      marginBottom: "5%",
                      fontSize: "16px",
                      lineHeight: "16px",
                    }}
                  >
                    Batch Date
                  </InputLabel>
                  <div
                    style={{
                      width: widthTotal > 600 ? "320px" : "100%",
                    }}
                  >
                    <DatePicker
                      selected={form.batch_date}
                      onChange={(date) => {
                        let obj = {
                          name: "batch_date",
                          value: date,
                        };
                        handleChange(obj);
                      }}
                      customInput={<CustomInput />}
                      className="custom-datepicker"
                    />
                  </div>
                </div>
                {widthTotal < 600 ? (
                  <div
                    style={{
                      paddingTop: "20px",
                      flexDirection: "column",
                      display: "flex",
                      float: "right",
                      width: widthTotal < 600 ? "100%" : "",
                      justifyContent: "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        float: "right",
                        paddingTop: "10px",

                        width: widthTotal > 600 ? "320px" : "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        id="linkbtn"
                      >
                       Generate Link
                      </Button>

                      <input
                        type="text"
                        value={link ? link : "Generate Link"}
                        id="linkInput"
                      />
                      <button
                        style={{
                          width: "70px",
                          height: "60px",
                          backgroundColor: "#73a0ec",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                          border: "none",
                          alignItems: "center",
                        }}
                        onClick={copyLink}
                      >
                        {!copied ? (
                          <ContentCopyIcon style={{ color: "white" }} />
                        ) : (
                          <CheckIcon style={{ color: "white" }} />
                        )}
                      </button>
                    </div>
                    {link.data ? (
                      <img
                        src={Frame}
                        style={{
                          height: "13px",
                          width: "43px",
                          marginTop: "1%",
                          marginLeft: "20px",
                        }}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {error && widthTotal < 600 ? (
                  <Alert
                    severity="error"
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      width: "300px",
                      justifyContent: "center",
                    }}
                  >
                    Invalid Input! Try Again
                  </Alert>
                ) : (
                  ""
                )}
                <div
                  style={{
                    paddingTop: widthTotal > 600 ? "30px" : "30px",
                    paddingBottom: widthTotal < 600 && "30px",

                    width: widthTotal > 600 ? "320px" : "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      height: "50px",
                      marginTop: widthTotal < 600 ? "10px" : "",
                      width: widthTotal > 600 ? "300px" : "100%",
                      backgroundColor: "#FFC846",
                      color: "black",

                      textTransform: "none",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                    onClick={handleNewClick}
                  >
                    Reset
                  </Button>
                  {widthTotal > 600 ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: -5,
                        right: 0,
                        zIndex: -1,
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      <img src={LinkImg} alt="." />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {error && widthTotal > 600 ? (
              <Alert
                severity="error"
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "center",
                }}
              >
                Invalid Input! Try Again
              </Alert>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
